import React, { useRef, useCallback } from "react";
import "./ZeichenMassnahme.css";
import { toPng } from 'html-to-image';

function importAll(r) {
    let symbole = {};
    r.keys().map((item, index) => { symbole[item.replace('./', '')] = r(item); });
    return symbole;
}

const symbole = importAll(require.context('/src/symbole/innen', false, /\.(png|jpe?g|svg)$/));
const hintergrund = importAll(require.context('/src/symbole/grundzeichen/massnahme', false, /\.(png|jpe?g|svg)$/));
const groesseno = importAll(require.context('/src/symbole/groessenordnung', false, /\.(png|jpe?g|svg)$/));
const log = importAll(require.context('/src/symbole/fuelog/massnahme/log', false, /\.(png|jpe?g|svg)$/));
const fue = importAll(require.context('/src/symbole/fuelog/massnahme/fue', false, /\.(png|jpe?g|svg)$/));

export default function ZeichenMassnahme (
    { data }) {

        const ref = useRef(null)
        let fileName
        if (data.groessenordnung==="keine") {
            fileName = `taktZ_${data.grundzeichen}_${data.activeSymboleInnen.join("+")}.png`
        } else {
            fileName = `taktZ_${data.groessenordnung}_${data.activeSymboleInnen.join("+")}.png`
        }
        const onButtonClick = useCallback(() => {
            if (ref.current === null) {
                return
            }
    
            toPng(ref.current, { cacheBust: true, })
                .then((dataUrl) => {
                    const link = document.createElement('a')
                    link.download = fileName
                    link.href = dataUrl
                    link.click()
                })
                .catch((err) => {
                    console.log(err)
                })
        }, [ref])

    const imgList = data.activeSymboleInnen.map((symbol) => <img src={symbole[`${symbol}.png`]} />)

    return (
        <div className="Container ZeichenMassnahme">
            <div className="Massnahme-Innen" id="domEl" ref={ref} >
                <div className="Massnahme-Grundzeichen Massnahme-Container">
                    <img src={hintergrund[`fill-${data.fuellfarbe}.png`]} />
                    <img src={hintergrund[`line-${data.linienfarbe}.png`]} />    
                </div>
                <div className="Massnahme-Groessenordnung Massnahme-Container">
                    <img src={groesseno[`${data.groessenordnung}.png`]} />
                </div>
                <div className="Massnahme-Vstkverm Massnahme-Container">
                    <p>{data.vstkverm === "Verstärkt (+)" ? "(+)" : (data.vstkverm === "Vermindert (-)" ? "(-)" : (data.vstkverm === "Vstk/Verm (+/-)" ? "(+/-)" : ""))}</p>
                </div>
                <div className="Massnahme-FreitextAussen Massnahme-Container">
                    <p>{data.freitextAussenObenRechts}</p>
                </div>
                <div className={`Massnahme-Container ${data.fuehrung ? "Massnahme-Fuehrung-On" : "Massnahme-Fuehrung-Off"} `}>
                <img src={fue[`${data.linienfarbe}.png`]} />
                </div>
                <div className="Massnahme-Container Massnahme-FreitextInnenOben">
                    <p>{data.freitextInnenObenLinks}</p>
                </div>
                <div className="Massnahme-Container Massnahme-FreitextInnenUnten">
                    <p>{data.freitextInnenUntenRechts}</p>
                </div>
                <div className="Massnahme-Symbol Massnahme-Container">
                    <p>{data.textInput}</p>
                    {imgList}
                </div>
                <div className={`Massnahme-Container ${data.logistik ? "Massnahme-Logistik-On" : "Massnahme-Logistik-Off"} `}>
                <img src={log[`${data.linienfarbe}.png`]} />
                </div>
                <div className="Massnahme-Rufname Massnahme-Container">
                    <p>{data.rufname}</p>
                </div>
                <div className="Massnahme-Von Massnahme-Container">
                    <p>{data.bewegungVon}</p>
                </div>
                <div className="Massnahme-Nach Massnahme-Container">
                    <p>{data.bewegungNach}</p>
                </div>
                <div className="Massnahme-Bewegungsart Massnahme-Container">
                    <img src={symbole[`${data.bewegungsart}.png`]} />
                </div>
                <div className="Massnahme-Staerke Massnahme-Container">
                    <p>{data.staerke}</p>
                </div>
                <div className="Massnahme-Dtg Massnahme-Container">
                    <p>{data.dtg}</p>
                </div>
            </div>
            <div className="Massnahme-Download Massnahme-Container">
                <button onClick={onButtonClick} >Download</button>
            </div>
        </div>
    )
}
