import React, { useRef, useCallback } from "react";
import "./ZeichenPerson.css";
import { toPng } from 'html-to-image';

function importAll(r) {
    let symbole = {};
    r.keys().map((item, index) => { symbole[item.replace('./', '')] = r(item); });
    return symbole;
}

const symbole = importAll(require.context('/src/symbole/innen', false, /\.(png|jpe?g|svg)$/));
const hintergrund = importAll(require.context('/src/symbole/grundzeichen/person', false, /\.(png|jpe?g|svg)$/));
const groesseno = importAll(require.context('/src/symbole/groessenordnung', false, /\.(png|jpe?g|svg)$/));
const fuelog = importAll(require.context('/src/symbole/fuelog/person', false, /\.(png|jpe?g|svg)$/));
const ortsf = importAll(require.context('/src/symbole/ortsfest', false, /\.(png|jpe?g|svg)$/));

export default function ZeichenPerson(
    { data }) {

        const ref = useRef(null)
        let fileName
        if (data.groessenordnung==="keine") {
            fileName = `taktZ_${data.grundzeichen}_${data.activeSymboleInnen.join("+")}.png`
        } else {
            fileName = `taktZ_${data.groessenordnung}_${data.activeSymboleInnen.join("+")}.png`
        }
        const onButtonClick = useCallback(() => {
            if (ref.current === null) {
                return
            }
    
            toPng(ref.current, { cacheBust: true, })
                .then((dataUrl) => {
                    const link = document.createElement('a')
                    link.download = fileName
                    link.href = dataUrl
                    link.click()
                })
                .catch((err) => {
                    console.log(err)
                })
        }, [ref])


    // let imgList = data.activeSymboleInnen.map( function (symbol) { 
    //     if (symbol.endsWith("-")) {
    //         <img src={symbole[`${symbol}${data.linienfarbe}.png`]} />
    //     } else {
    //         <img src={symbole[`${symbol}.png`]} />
    //     }});
    
    
    
    const imgList = data.activeSymboleInnen.map((symbol) => <img src={symbole[symbol.endsWith("-") ? `${symbol}${data.linienfarbe}.png` : `${symbol}.png`]} />)


    return (
        <div className="Container ZeichenPerson">
            <div className="Person-Innen" id="domEl" ref={ref} >
                <div className="Person-Grundzeichen Person-Container">
                    <img src={hintergrund[`fill-${data.fuellfarbe}.png`]} />
                    <img src={hintergrund[`line-${data.linienfarbe}.png`]} />
                </div>
                <div className="Person-Groessenordnung Person-Container">
                    <img src={groesseno[`${data.groessenordnung}.png`]} />
                </div>
                <div className="Person-Vstkverm Person-Container">
                    <p>{data.vstkverm === "Verstärkt (+)" ? "(+)" : (data.vstkverm === "Vermindert (-)" ? "(-)" : (data.vstkverm === "Vstk/Verm (+/-)" ? "(+/-)" : ""))}</p>
                </div>
                <div className="Person-FreitextAussen Person-Container">
                    <p>{data.freitextAussenObenRechts}</p>
                </div>
                <div className={`Person-Container ${data.fuehrung ? "Person-Fuehrung-On" : "Person-Fuehrung-Off"} `}>
                    <img src={fuelog[`${data.linienfarbe}.png`]} />
                </div>
                <div className={`Person-Container ${data.ortsfest ? "Person-Ortsfest-On" : "Person-Ortsfest-Off"} `}>
                    <img src={ortsf[`${data.linienfarbe}.png`]} />
                </div>
                <div className="Person-Container Person-FreitextInnenOben">
                    <p>{data.freitextInnenObenLinks}</p>
                </div>
                <div className="Person-Container Person-FreitextInnenUnten">
                    <p>{data.freitextInnenUntenRechts}</p>
                </div>
                <div className="Person-Symbol Person-Container">
                    <p>{data.textInput}</p>
                    {imgList}
                </div>
                <div className={`Person-Container ${data.logistik ? "Person-Logistik-On" : "Person-Logistik-Off"} `}>
                    <img src={fuelog[`${data.linienfarbe}.png`]} />
                </div>
                <div className="Person-Rufname Person-Container">
                    <p>{data.rufname}</p>
                </div>
                <div className="Person-Von Person-Container">
                    <p>{data.bewegungVon}</p>
                </div>
                <div className="Person-Nach Person-Container">
                    <p>{data.bewegungNach}</p>
                </div>
                <div className="Person-Bewegungsart Person-Container">
                    <img src={symbole[`${data.bewegungsart}.png`]} />
                </div>
                <div className="Person-Staerke Person-Container">
                    <p>{data.staerke}</p>
                </div>
                <div className="Person-Dtg Person-Container">
                    <p>{data.dtg}</p>
                </div>
            </div>
            <div className="Person-Download Person-Container">
                <button onClick={onButtonClick} >Download</button>
            </div>
        </div>
    )
}
