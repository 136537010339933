import React, { useState } from 'react';
import './App.css';
import { symbolTable } from "../assets/symbolTable"

import Freitext from '../components/Freitext';
import ZeichenEinheit from '../components/Einheit/ZeichenEinheit';
import ZeichenStelle from '../components/Stelle/ZeichenStelle';
import ZeichenPerson from '../components/Person/ZeichenPerson';
import ZeichenMassnahme from '../components/Massnahme/ZeichenMassnahme';
import ZeichenFahrzeug from '../components/Fahrzeug/ZeichenFahrzeug';
import ZeichenBoot from '../components/Boot/ZeichenBoot';
import ZeichenEreignis from '../components/Ereignis/ZeichenEreignis';
import ZeichenGefahr from '../components/Gefahr/ZeichenGefahr';
import Groessenordnung from '../components/Groessenordnung';
import FuehrungLogistik from '../components/FuehrungLogistik';
import VerstaerktVermindert from '../components/VerstaerktVermindert';
import FreitextInnenUntenRechts from '../components/FreitextInnenUntenRechts';
import Bewegungsart from '../components/Bewegungsart';
import Beweglichkeit from '../components/Beweglichkeit';
import SymbolInnen from '../components/SymbolInnen';
import Grundzeichen from '../components/Grundzeichen';
import Linienfarbe from '../components/Linienfarbe';
import Fuellfarbe from '../components/Fuellfarbe';


function App() {

  const [rufname, setRufname] = useState('');
  const [groessenordnung, setGroessenordnung] = useState('keine')
  const [fuehrung, setFuehrung] = useState(false)
  const [logistik, setLogistik] = useState(false)
  const [fuehrungsstelle, setFuehrungsstelle] = useState(false)
  const [ortsfest, setOrtsfest] = useState(false)
  const [vstkverm, setVstkverm] = useState('')
  const [freitextAussenObenRechts, setFreitextAussenObenRechts] = useState('')
  const [freitextInnenObenLinks, setFreitextInnenObenLinks] = useState('')
  const [freitextInnenUntenRechts, setFreitextInnenUntenRechts] = useState('')
  const [staerke, setStaerke] = useState('');
  const [bewegungsart, setBewegungsart] = useState('keine')
  const [bewegungVon, setBewegungVon] = useState('')
  const [bewegungNach, setBewegungNach] = useState('')
  const [beweglichkeit, setBeweglichkeit] = useState('keine')
  const [dtg, setDtg] = useState('')
  const [grundzeichen, setGrundzeichen] = useState('Einheit')
  const [linienfarbe, setLinienfarbe] = useState('schwarz')
  const [fuellfarbe, setFuellfarbe] = useState('weiss')

  const [symboleInnen, setSymboleInnen] = useState(symbolTable)
  const [textInput, setTextInput] = useState('')


  const handleChangeRufname = (e) => setRufname(e.target.value);
  const handleChangeGroessenordnung = (e) => setGroessenordnung(e.target.value);
  const toggleFuehrung = () => fuehrung ? setFuehrung(false) : setFuehrung(true);
  const toggleLogistik = () => logistik ? setLogistik(false) : setLogistik(true);
  const toggleFuehrungsstelle = () => fuehrungsstelle ? setFuehrungsstelle(false) : setFuehrungsstelle(true);
  const toggleOrtsfest = () => ortsfest ? setOrtsfest(false) : setOrtsfest(true);
  const alertFuehrungsstelle = () => alert('Dazu muss das Grundzeichen "Einheit" ausgewählt sein.')
  const alertOrtsfest = () => alert('Dazu muss ein anderes Grundzeichen ausgewählt sein.')
  const handleChangeVerstaerktVermindert = (e) => setVstkverm(e.target.value);
  const handleChangeFreitextAussenObenRechts = (e) => setFreitextAussenObenRechts(e.target.value)
  const handleChangeFreitextInnenObenLinks = (e) => setFreitextInnenObenLinks(e.target.value)
  const handleChangeFreitextInnenUntenRechts = (e) => setFreitextInnenUntenRechts(e.target.value)
  const handleChangeStaerke = (e) => setStaerke(e.target.value)
  const handleChangeBewegungsart = (e) => setBewegungsart(e.target.value)
  const handleChangeBewegungVon = (e) => setBewegungVon(e.target.value)
  const handleChangeBewegungNach = (e) => setBewegungNach(e.target.value)
  const handleChangeBeweglichkeit = (e) => setBeweglichkeit(e.target.value)
  const alertBeweglichkeit = () => alert('Dazu muss das Grundzeichen "Fahrzeug" ausgewählt sein.')
  const handleChangeDtg = (e) => setDtg(e.target.value)
  const handleChangeLinienfarbe = (e) => setLinienfarbe(e.target.value)
  const handleChangeFuellfarbe = (e) => setFuellfarbe(e.target.value)
  const handleChangeTextInput = (e) => setTextInput(e.target.value)
  const setChangeTextInput = (e) => setTextInput(e)

  const resetSymboleInnen = () => {

    const newArr = symboleInnen.map((symbol) =>
      symbol = {
        name: symbol.name,
        src: symbol.src,
        category: symbol.category,
        checked: false
      })

    setSymboleInnen(newArr)
  }
  const handleChangeSymbol = (e) => {

    const symbolIndex = symboleInnen.findIndex(i => i.src === e.target.id)

    const newObj = {
      name: symboleInnen[symbolIndex].name,
      src: symboleInnen[symbolIndex].src,
      category: symboleInnen[symbolIndex].category,
      checked: !symboleInnen[symbolIndex].checked
    }

    const newArr = symboleInnen.map((symbol) =>
      symbol.src === e.target.id ? newObj : symbol)
    setSymboleInnen(newArr)
  }

  const handleChangeGrundzeichen = (e) => {
    resetSymboleInnen()
    setGrundzeichen(e.target.value)
  }

  const activeSymboleInnen = symboleInnen.filter(i => i.checked).map(j => j.src)

  const zeichenProps = {
    grundzeichen: grundzeichen,
    fuehrung: fuehrung,
    logistik: logistik,
    fuehrungsstelle: fuehrungsstelle,
    ortsfest: ortsfest,
    groessenordnung: groessenordnung,
    vstkverm: vstkverm,
    freitextAussenObenRechts: freitextAussenObenRechts,
    freitextInnenObenLinks: freitextInnenObenLinks,
    freitextInnenUntenRechts: freitextInnenUntenRechts,
    rufname: rufname,
    bewegungsart: bewegungsart,
    bewegungVon: bewegungVon,
    bewegungNach: bewegungNach,
    staerke: staerke,
    beweglichkeit: beweglichkeit,
    dtg: dtg,
    activeSymboleInnen: activeSymboleInnen,
    linienfarbe: linienfarbe,
    fuellfarbe: fuellfarbe,
    textInput: textInput
  }

  let c = { grundzeichen }.grundzeichen
  let zeichenComponent
  switch (c) {
    case "Einheit":
      zeichenComponent = <ZeichenEinheit data={zeichenProps} />
      break
    case "Stelle":
      zeichenComponent = <ZeichenStelle data={zeichenProps} />
      break
    case "Person":
      zeichenComponent = <ZeichenPerson data={zeichenProps} />
      break
    case "Maßnahme":
      zeichenComponent = <ZeichenMassnahme data={zeichenProps} />
      break
    case "Fahrzeug":
      zeichenComponent = <ZeichenFahrzeug data={zeichenProps} />
      break
    case "Boot":
      zeichenComponent = <ZeichenBoot data={zeichenProps} />
      break
    case "Ereignis":
      zeichenComponent = <ZeichenEreignis data={zeichenProps} />
      break
    case "Gefahr":
      zeichenComponent = <ZeichenGefahr data={zeichenProps} />
  }

  let fuehrungLogistikComponent
    if (c === "Einheit") {
      fuehrungLogistikComponent =
      <FuehrungLogistik 
        key="fuelog1"
        fuehrung={fuehrung}
        logistik={logistik}
        fuehrungsstelle={fuehrungsstelle}
        ortsfest={ortsfest}
        toggleFuehrung={toggleFuehrung}
        toggleLogistik={toggleLogistik}
        toggleFuehrungsstelle={toggleFuehrungsstelle}
        toggleOrtsfest={toggleOrtsfest}
      />
    } else if (c === "Person" || c === "Stelle" || c ==="Fahrzeug" || c ==="Sonstiges") {
      fuehrungLogistikComponent =
      <FuehrungLogistik 
        key="fuelog2"
        fuehrung={fuehrung}
        logistik={logistik}
        fuehrungsstelle={false}
        ortsfest={ortsfest}
        toggleFuehrung={toggleFuehrung}
        toggleLogistik={toggleLogistik}
        toggleFuehrungsstelle={alertFuehrungsstelle}
        toggleOrtsfest={toggleOrtsfest}
      />
    } else {
      fuehrungLogistikComponent =
      <FuehrungLogistik 
        key="fuelog3"
        fuehrung={fuehrung}
        logistik={logistik}
        fuehrungsstelle={false}
        ortsfest={false}
        toggleFuehrung={toggleFuehrung}
        toggleLogistik={toggleLogistik}
        toggleFuehrungsstelle={alertFuehrungsstelle}
        toggleOrtsfest={alertOrtsfest}
      />
    }

    let beweglichkeitComponent
    if (c === "Fahrzeug") {
      beweglichkeitComponent =
      <Beweglichkeit
      beweglichkeit={beweglichkeit}
      onChangeBeweglichkeit={handleChangeBeweglichkeit}

      />
    } else {
      beweglichkeitComponent =
      <Beweglichkeit
      beweglichkeit={""}
      onChangeBeweglichkeit={alertBeweglichkeit}
      />
    }



  return (
    <div className="App">
      <header className="App-header">
        <p>
          Taktische Zeichen Generator
        </p>
      </header>
      {zeichenComponent}
      <Grundzeichen
        onChangeGrundzeichen={handleChangeGrundzeichen}
        grundzeichen={grundzeichen}
      />
      {fuehrungLogistikComponent}
      
      <Groessenordnung
        onChangeGroessenordnung={handleChangeGroessenordnung}
        groessenordnung={groessenordnung}
      />
      <Linienfarbe
        onChangeLinienfarbe={handleChangeLinienfarbe}
        linienfarbe={linienfarbe}
      />
      <Fuellfarbe
        onChangeFuellfarbe={handleChangeFuellfarbe}
        fuellfarbe={fuellfarbe}
      />
      <div className='Container Buttons'></div>
      <VerstaerktVermindert
        vstkverm={vstkverm}
        onChangeVerstaerktVermindert={handleChangeVerstaerktVermindert}
      />
      <Freitext
        id="FreitextAussenObenRechts"
        onChangeFreitext={handleChangeFreitextAussenObenRechts}
        freitext={freitextAussenObenRechts}
        label="Freitext (z.B. Anzahl)"
      />
      <Freitext
        id="FreitextInnenObenLinks"
        onChangeFreitext={handleChangeFreitextInnenObenLinks}
        freitext={freitextInnenObenLinks}
        label="Freitext innen"
      />
      <FreitextInnenUntenRechts
        onChangeFreitextInnenUntenRechts={handleChangeFreitextInnenUntenRechts}
        freitextInnenUntenRechts={freitextInnenUntenRechts}
      />
      <Freitext
        id="Rufname"
        onChangeFreitext={handleChangeRufname}
        freitext={rufname}
        label="Rufname"
      />
      <Bewegungsart
        bewegungsart={bewegungsart}
        onChangeBewegungsart={handleChangeBewegungsart}
        bewegungVon={bewegungVon}
        onChangeBewegungVon={handleChangeBewegungVon}
        bewegungNach={bewegungNach}
        onChangeBewegungNach={handleChangeBewegungNach}
      />
      <Freitext
        id="Staerke"
        onChangeFreitext={handleChangeStaerke}
        freitext={staerke}
        label="Stärke"
      />
      {beweglichkeitComponent}
      <Freitext
        id="DTG"
        freitext={dtg}
        onChangeFreitext={handleChangeDtg}
        label="Datum-Zeit-Gruppe"
      />
      <SymbolInnen
        symboleInnen={symboleInnen}
        onChangeSymbol={handleChangeSymbol}
        textInput={textInput}
        onChangeTextInput={handleChangeTextInput}
        reset={resetSymboleInnen}
        setTextInput={setChangeTextInput}
        grundzeichen={grundzeichen}
      />

    </div>
  );
}

export default App;

//Ideenspeicher:
// - Größenordnung Zugtrupp