import React from "react";

export default function VerstaerktVermindert ({onChangeVerstaerktVermindert}) {
    return(
        <div className="Container VerstaerktVermindert">
            <label>Vstk/Verm</label>
            <select onChange={onChangeVerstaerktVermindert}>
                <option></option>
                <option>Verstärkt (+)</option>
                <option>Vermindert (-)</option>
                <option>Vstk/Verm (+/-)</option>
            </select>
        </div>
    )
}