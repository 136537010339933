import React from "react";


export default function Bewegungsart ({onChangeBewegungsart, bewegungVon, bewegungNach, onChangeBewegungVon, onChangeBewegungNach}) {
    return (
        <div className="Container Bewegungsart">
            <div id="Bewegungsart-Dropdown">
                <label htmlFor="bewegungsart">Bewegungsart</label>
                <select onChange={onChangeBewegungsart} id="bewegungsart">
                    <option value="keine"></option>
                    <option value={"bewegung"}>gerichtete Bewegung</option>
                    <option value={"zutransportieren"}>zu transportieren</option>
                    <option value={"transportiert"}>transportiert</option>
                    <option value="bewegungzweiseitig">beidseitige Bewegung</option>
                    <option value={"sammeln"}>Sammeln</option>
                </select>
            </div>
            <div id="Bewegungsart-VonNach">
                <label htmlFor="von">Von</label>
                <input 
                    type="text" 
                    id="von"
                    onChange={onChangeBewegungVon}
                    value={bewegungVon}
                /> <br/>
                <label htmlFor="nach">Nach</label>
                <input 
                    type="text" 
                    id="nach" 
                    onChange={onChangeBewegungNach}
                    vanue={bewegungNach}
                />
            </div>
        </div>
    )
}