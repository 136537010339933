import React from "react";

export default function Groessenordnung ({onChangeGroessenordnung}) {
    return(
        <div className="Container Groessenordnung">
            <label htmlFor="groessenordnung">Größenordnung</label>
            <select onChange={onChangeGroessenordnung} id="groessenordnung">
                <option value="keine"></option>
                <option>Trupp</option>
                <option>Staffel</option>
                <option>Gruppe</option>
                <option>Zug</option>
                <option>Verband I</option>
                <option>Verband II</option>
                <option>Verband III</option>
            </select>
        </div>
    )
}