import React, { useRef, useCallback } from "react";
import "./ZeichenEinheit.css";
import { toPng } from 'html-to-image';

function importAll(r) {
    let symbole = {};
    r.keys().map((item, index) => { symbole[item.replace('./', '')] = r(item); });
    return symbole;
}

const symbole = importAll(require.context('/src/symbole/innen', false, /\.(png|jpe?g|svg)$/));
const hintergrund = importAll(require.context('/src/symbole/grundzeichen/einheit', false, /\.(png|jpe?g|svg)$/));
const groesseno = importAll(require.context('/src/symbole/groessenordnung', false, /\.(png|jpe?g|svg)$/));
const ortsf = importAll(require.context('/src/symbole/ortsfest', false, /\.(png|jpe?g|svg)$/));

export default function ZeichenEinheit(
    { data }) {

    const ref = useRef(null)
    let fileName
    if (data.groessenordnung==="keine") {
        fileName = `taktZ_${data.grundzeichen}_${data.activeSymboleInnen.join("+")}.png`
    } else {
        fileName = `taktZ_${data.groessenordnung}_${data.activeSymboleInnen.join("+")}.png`
    }
    const onButtonClick = useCallback(() => {
        if (ref.current === null) {
            return
        }

        toPng(ref.current, { cacheBust: true, })
            .then((dataUrl) => {
                const link = document.createElement('a')
                link.download = fileName
                link.href = dataUrl
                link.click()
            })
            .catch((err) => {
                console.log(err)
            })
    }, [ref])
    
    const imgList = data.activeSymboleInnen.map((symbol) => <img src={symbole[`${symbol}.png`]} />)

    return (
        <div className="Container ZeichenEinheit" >
            <div className="Einheit-Innen" id="domEl" ref={ref} >
                <div className="Einheit-Grundzeichen Einheit-Container">
                    <img src={hintergrund[`fill-${data.fuellfarbe}.png`]} />
                    <img src={hintergrund[`line-${data.linienfarbe}.png`]} />
                </div>
                <div className="Einheit-Groessenordnung Einheit-Container">
                    <img src={groesseno[`${data.groessenordnung}.png`]} />
                </div>
                <div className="Einheit-Vstkverm Einheit-Container">
                    <p>{data.vstkverm === "Verstärkt (+)" ? "(+)" : (data.vstkverm === "Vermindert (-)" ? "(-)" : (data.vstkverm === "Vstk/Verm (+/-)" ? "(+/-)" : ""))}</p>
                </div>
                <div className="Einheit-FreitextAussen Einheit-Container">
                    <p>{data.freitextAussenObenRechts}</p>
                </div>
                <div className={`Einheit-Container ${data.fuehrung ? "Einheit-Fuehrung-On" : "Einheit-Fuehrung-Off"} `}>

                </div>
                <div className={`Einheit-Container ${data.fuehrung ? "Einheit-FreitextInnenOben-Fuehrung" : "Einheit-FreitextInnenOben"} `}>
                    <p>{data.freitextInnenObenLinks}</p>
                </div>
                <div className={`Einheit-Container ${data.logistik ? "Einheit-FreitextInnenUnten-Logistik" : "Einheit-FreitextInnenUnten"} `}>
                    <p>{data.freitextInnenUntenRechts}</p>
                </div>
                <div className={`Einheit-Container ${data.ortsfest ? "Einheit-Ortsfest-On" : "Einheit-Ortsfest-Off"} `}>
                    <img src={ortsf[`${data.linienfarbe}.png`]} />
                </div>
                <div className="Einheit-Symbol Einheit-Container">
                    <p>{data.textInput}</p>
                    {imgList}
                </div>
                <div className={`Einheit-Container ${data.logistik ? "Einheit-Logistik-On" : "Einheit-Logistik-Off"} `}>

                </div>
                <div className={`Einheit-Container ${data.fuehrungsstelle ? "Einheit-Fuehrungsstelle-On" : "Einheit-Fuehrungsstelle-Off"} `}>

                </div>
                <div className="Einheit-Rufname Einheit-Container">
                    <p>{data.rufname}</p>
                </div>
                <div className="Einheit-Von Einheit-Container">
                    <p>{data.bewegungVon}</p>
                </div>
                <div className="Einheit-Nach Einheit-Container">
                    <p>{data.bewegungNach}</p>
                </div>
                <div className="Einheit-Bewegungsart Einheit-Container">
                    <img src={symbole[`${data.bewegungsart}.png`]} />
                </div>
                <div className="Einheit-Staerke Einheit-Container">
                    <p>{data.staerke}</p>
                </div>
                <div className="Einheit-Dtg Einheit-Container">
                    <p>{data.dtg}</p>
                </div>
            </div>
            <div className="Einheit-Download Einheit-Container">
                <button onClick={onButtonClick} >Download</button>
            </div>
        </div>
    )
}
