import React from "react";

export default function Fuellfarbe ({onChangeFuellfarbe, fuellfarbe}) {
    return(
        <div className="Container Fuellfarbe">
            <label htmlFor="fuellfarbe">Füllfarbe</label>
            <select onChange={onChangeFuellfarbe} id="Fuellfarbe" value={fuellfarbe}>
                <option value="weiss">weiß</option>
                <option>gelb</option>
                <option>rot</option>
                <option>blau</option>
                <option value="gruen">grün</option>
                <option>braun</option>
                <option>orange</option>
                <option value="transparent">keine</option>
            </select>
        </div>)
}