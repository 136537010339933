import React from "react";

export default function FreitextInnenUntenRechts ({onChangeFreitextInnenUntenRechts, freitextInnenUntenRechts}) {

    return (
        <div className="Container FreitextInnenUntenRechts" >
            <label>Organisation</label>
            <input onChange={onChangeFreitextInnenUntenRechts} value={freitextInnenUntenRechts} maxLength="4" />
        </div>
    )
}