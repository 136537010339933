import React, { useRef, useCallback } from "react";
import "./ZeichenStelle.css";
import { toPng } from 'html-to-image';

function importAll(r) {
    let symbole = {};
    r.keys().map((item, index) => { symbole[item.replace('./', '')] = r(item); });
    return symbole;
}

const symbole = importAll(require.context('/src/symbole/innen', false, /\.(png|jpe?g|svg)$/));
const hintergrund = importAll(require.context('/src/symbole/grundzeichen/stelle', false, /\.(png|jpe?g|svg)$/));
const groesseno = importAll(require.context('/src/symbole/groessenordnung', false, /\.(png|jpe?g|svg)$/));
const fuelog = importAll(require.context('/src/symbole/fuelog/stelle', false, /\.(png|jpe?g|svg)$/));
const ortsf = importAll(require.context('/src/symbole/ortsfest', false, /\.(png|jpe?g|svg)$/));

export default function ZeichenStelle (
    { data }) {

        const ref = useRef(null)
        let fileName
        if (data.groessenordnung==="keine") {
            fileName = `taktZ_${data.grundzeichen}_${data.activeSymboleInnen.join("+")}.png`
        } else {
            fileName = `taktZ_${data.groessenordnung}_${data.activeSymboleInnen.join("+")}.png`
        }
        const onButtonClick = useCallback(() => {
            if (ref.current === null) {
                return
            }
    
            toPng(ref.current, { cacheBust: true, })
                .then((dataUrl) => {
                    const link = document.createElement('a')
                    link.download = fileName
                    link.href = dataUrl
                    link.click()
                })
                .catch((err) => {
                    console.log(err)
                })
        }, [ref])

    const imgList = data.activeSymboleInnen.map((symbol) => <img src={symbole[`${symbol}.png`]} />)

    console.log(`line-${data.linienfarbe}.png`)

    return (
        <div className="Container ZeichenStelle">
            <div className="Stelle-Innen" id="domEl" ref={ref} >
                <div className="Stelle-Grundzeichen Stelle-Container">
                    <img src={hintergrund[`fill-${data.fuellfarbe}.png`]} />
                    <img src={hintergrund[`line-${data.linienfarbe}.png`]} />    
                </div>
                <div className="Stelle-Groessenordnung Stelle-Container">
                    <img src={groesseno[`${data.groessenordnung}.png`]} />
                </div>
                <div className="Stelle-Vstkverm Stelle-Container">
                    <p>{data.vstkverm === "Verstärkt (+)" ? "(+)" : (data.vstkverm === "Vermindert (-)" ? "(-)" : (data.vstkverm === "Vstk/Verm (+/-)" ? "(+/-)" : ""))}</p>
                </div>
                <div className="Stelle-FreitextAussen Stelle-Container">
                    <p>{data.freitextAussenObenRechts}</p>
                </div>
                <div className={`Stelle-Container ${data.ortsfest ? "Stelle-Ortsfest-On" : "Stelle-Ortsfest-Off"} `}>
                    <img src={ortsf[`${data.linienfarbe}.png`]} />
                </div>
                <div className={`Stelle-Container ${data.fuehrung ? "Stelle-Fuehrung-On" : "Stelle-Fuehrung-Off"} `}>
                    <img src={fuelog[`${data.linienfarbe}.png`]} />
                </div>
                <div className="Stelle-Container Stelle-FreitextInnenOben">
                    <p>{data.freitextInnenObenLinks}</p>
                </div>
                <div className="Stelle-Container Stelle-FreitextInnenUnten">
                    <p>{data.freitextInnenUntenRechts}</p>
                </div>
                <div className="Stelle-Symbol Stelle-Container">
                    <p>{data.textInput}</p>
                    {imgList}
                </div>
                <div className={`Stelle-Container ${data.logistik ? "Stelle-Logistik-On" : "Stelle-Logistik-Off"} `}>
                <img src={fuelog[`${data.linienfarbe}.png`]} />
                </div>
                <div className="Stelle-Rufname Stelle-Container">
                    <p>{data.rufname}</p>
                </div>
                <div className="Stelle-Von Stelle-Container">
                    <p>{data.bewegungVon}</p>
                </div>
                <div className="Stelle-Nach Stelle-Container">
                    <p>{data.bewegungNach}</p>
                </div>
                <div className="Stelle-Bewegungsart Stelle-Container">
                    <img src={symbole[`${data.bewegungsart}.png`]} />
                </div>
                <div className="Stelle-Staerke Stelle-Container">
                    <p>{data.staerke}</p>
                </div>
                <div className="Stelle-Dtg Stelle-Container">
                    <p>{data.dtg}</p>
                </div>
            </div>
            <div className="Stelle-Download Stelle-Container">
                <button onClick={onButtonClick} >Download</button>
            </div>
        </div>
    )
}
