import React, {useState, useEffect} from "react";


function importAll(r) {
    let symbole = {};
    r.keys().map((item, index) => { symbole[item.replace('./', '')] = r(item); });
    return symbole;
  }
  
  const symbole = importAll(require.context('../symbole/innen', false, /\.(png|jpe?g|svg)$/));
  
  


export default function SymbolInnen ({symboleInnen, onChangeSymbol, textInput, onChangeTextInput, reset, setTextInput, grundzeichen}) {
    
    const [textInputChecked, setTextInputChecked] = useState(false)

    const handleChangeTextInputChecked = () => {
        if (!textInputChecked) {
            reset()
            setTextInputChecked(true)
        } else {
            setTextInputChecked(false)
            setTextInput('')
        }
    }


    useEffect(() => {
        if (!textInputChecked && textInput!== '') {
            setTextInputChecked(true)
            reset()
        } 
    } , [textInput])
   
    const textInputRow = (
        <tr>
            <td>
                <input 
                    type="checkbox" 
                    id="textInputCheck"
                    name="textInputCheck"
                    key="textInputCheck"
                    checked={textInputChecked}
                    onChange={handleChangeTextInputChecked}
                />
            </td>
            <td>
                <input
                    type="text" 
                    id="textInput"
                    name="textInput"
                    key="textInput"
                    value={textInput}
                    placeholder="Text"
                    onChange={onChangeTextInput}
                />
            </td>
        </tr>
    )

    const tableRows = symboleInnen.filter(symbol => symbol.category.some(cat => cat==={grundzeichen}.grundzeichen)).map((symbol) =>
        <tr>
            <td>
                <input 
                    type="checkbox" 
                    id={symbol.src} 
                    name={symbol.name}
                    key={symbol.src} 
                    checked={symbol.checked}
                    onChange={onChangeSymbol}
                />
            </td>
            <td>
                <label htmlFor={symbol.src}>{symbol.name}</label>
            </td>
            <td>
                <img src={symbole[`${symbol.src}.png`]} />
            </td>
        </tr>)

    return (
        <div className="Container Symbol" >
            <div className="Symbol-Header">
                <p>Symbol innen</p>
            </div>
            <div className="Table">
                <table>
                    <tbody>
                        {textInputRow}
                        {tableRows}
                    </tbody>
                </table>
            </div >
        </div>
    )
}
