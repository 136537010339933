export const symbolTable = [
    {
        name: "Wasserrettung",
        src: "wasserrettung",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Boot", "Maßnahme", "Ereignis"],
        checked: true
    },

    {
        name: "Brandbekämpfung",
        src: "brandbekaempfung",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Boot", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Sanität",
        src: "sanitaet",
        category: ["Stelle", "Person", "Boot", "Maßnahme"],
        checked: false
    },
    {
        name: "Sanität",
        src: "sanitaet2",
        category: ["Einheit", "Fahrzeug"],
        checked: false
    },
    {
        name: "Betreuung",
        src: "betreuung",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "CBRN",
        src: "cbrn",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Boot", "Maßnahme", "Gefahr", "Ereignis"],
        checked: false
    },
    {
        name: "Deichverteidigung",
        src: "deichverteidigung",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Boot", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Transport",
        src: "transport",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Boot", "Maßnahme"],
        checked: false
    },
    {
        name: "Pumpen",
        src: "pumpen",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme"],
        checked: false
    },
    {
        name: "Fahren auf Wasser",
        src: "fahrenaufwasser",
        category: ["Stelle", "Maßnahme"],
        checked: false
    },
    {
        name: "Dekon auf Wasser",
        src: "dekonwasser",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Boot", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Rettungshunde",
        src: "rettungshunde",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme"],
        checked: false
    },
    {
        name: "Bergung",
        src: "bergen",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Höhenrettung",
        src: "hoehenrettung",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "THL",
        src: "thl",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Boot", "Maßnahme"],
        checked: false
    },
    {
        name: "Beleuchtung",
        src: "beleuchtung",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Heben von Lasten",
        src: "hebenvonlasten",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Boot", "Maßnahme"],
        checked: false
    },
    {
        name: "Räumen",
        src: "raeumen",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme"],
        checked: false
    },
    {
        name: "Dekontamination",
        src: "dekon",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Boot", "Maßnahme"],
        checked: false
    },
    {
        name: "Sprengen",
        src: "sprengen",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Sandsäcke füllen",
        src: "sandsackfuellen",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Sandsackverbau",
        src: "sandsack",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Boot", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Ärztliche Versorgung",
        src: "arzt",
        category: ["Stelle", "Person", "Boot", "Maßnahme"],
        checked: false
    },
    {
        name: "Ärztliche Versorgung",
        src: "arzt2",
        category: ["Einheit", "Fahrzeug"],
        checked: false
    },
    {
        name: "Unterbringung",
        src: "unterbringung",
        category: ["Einheit", "Stelle", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Veterinärwesen",
        src: "veterinaer",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme", "Gefahr", "Ereignis"],
        checked: false
    },
    {
        name: "Verpflegung",
        src: "verpflegung",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Boot", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Betriebsstoffe",
        src: "betriebsstoffe",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Trinkwasser",
        src: "trinkwasser",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Brauchwasser",
        src: "brauchwasser",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Elektrizität",
        src: "elektrizitaet",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme", "Gefahr", "Ereignis"],
        checked: false
    },
    {
        name: "Instandsetzung",
        src: "inst",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Fernmeldewesen",
        src: "fernmelde",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Aufklärung",
        src: "aufklaerung",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Boot", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Warnen, Beschallen",
        src: "warnen",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "UAV Drohne",
        src: "uav",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Boot", "Maßnahme", "Gefahr", "Ereignis"],
        checked: false
    },
    {
        name: "Sonar",
        src: "sonar",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Boot", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Sirene",
        src: "sirene",
        category: ["Einheit", "Stelle", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Sprengmittel",
        src: "sprengmittel",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme", "Gefahr", "Ereignis"],
        checked: false
    },
    {
        name: "Zelt",
        src: "zelt",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme"],
        checked: false
    },
    {
        name: "Brücke",
        src: "bruecke",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme", "Gefahr", "Ereignis"],
        checked: false
    },
    {
        name: "Krankenhaus",
        src: "khs",
        category: ["Einheit"],
        checked: false
    },
    {
        name: "Sammeln",
        src: "sammeln",
        category: ["Einheit", "Stelle", "Person", "Maßnahme", "Ereignis"],
        checked: false
    },
    {
        name: "Verletzt",
        src: "verletzt-",
        category: ["Person", "Ereignis"],
        checked: false
    },
    {
        name: "Vermisst",
        src: "vermisst-",
        category: ["Person"],
        checked: false
    },
    {
        name: "Verschüttet",
        src: "verschuettet-",
        category: ["Person"],
        checked: false
    },
    {
        name: "Gerettet",
        src: "gerettet-",
        category: ["Person"],
        checked: false
    },
    {
        name: "Tot",
        src: "tot-",
        category: ["Person", "Ereignis"],
        checked: false
    },
    {
        name: "Fachberater",
        src: "fachberater-",
        category: ["Person"],
        checked: false
    },
    {
        name: "Boot",
        src: "boot",
        category: ["Einheit", "Stelle", "Person", "Fahrzeug", "Maßnahme", "Gefahr", "Ereignis"],
        checked: false
    },
    {
        name: "Fahrzeug",
        src: "fahrzeug",
        category: ["Einheit", "Person", "Fahrzeug", "Maßnahme", "Gefahr", "Ereignis"],
        checked: false
    },
    {
        name: "BR mit FüSt",
        src: "brfue",
        category: ["Stelle"],
        checked: false
    },
    {
        name: "BR (Bereitstellungsraum)",
        src: "fahrzeug",
        category: ["Stelle"],
        checked: false
    },
    {
        name: "BR mit Meldekopf",
        src: "brm",
        category: ["Stelle"],
        checked: false
    }
]

symbolTable.sort((a, b) => a.name.localeCompare(b.src))