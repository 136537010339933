import React from "react";

export default function Beweglichkeit ({onChangeBeweglichkeit, beweglichkeit}) {
    return(
        <div className="Container Beweglichkeit">
            <label htmlFor="beweglichkeit">Beweglichkeit</label>
            <select onChange={onChangeBeweglichkeit} id="beweglichkeit" value={beweglichkeit}>
                <option value="keine"></option>
                <option value="strasse">straßengebunden</option>
                <option value="gelaende">geländefähig/-gängig</option>
                <option value="wechsellader">Wechselladefahrzeug</option>
                <option value="ab">Abrollbehälter</option>
                <option value="haenger">Anhänger</option>
                <option value="schiene">auf Schiene</option>
                <option value="kette">auf Kette</option>
                <option>amphibisch</option>
            </select>
        </div>)
}