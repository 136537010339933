import React from "react";

export default function Linienfarbe ({onChangeLinienfarbe, linienfarbe}) {
    return(
        <div className="Container Linienfarbe">
            <label htmlFor="linienfarbe">Linienfarbe</label>
            <select onChange={onChangeLinienfarbe} id="linienfarbe" value={linienfarbe}>
                <option>schwarz</option>
                <option value="weiss">weiß</option>
                <option>rot</option>
                <option>blau</option>
                <option value="gruen">grün</option>
                <option>braun</option>
            </select>
        </div>)
}