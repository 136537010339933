import React, { useRef, useCallback } from "react";
import "./ZeichenFahrzeug.css";
import {toPng} from "html-to-image";

function importAll(r) {
    let symbole = {};
    r.keys().map((item, index) => { symbole[item.replace('./', '')] = r(item); });
    return symbole;
}

const symbole = importAll(require.context('/src/symbole/innen', false, /\.(png|jpe?g|svg)$/));
const hintergrund = importAll(require.context('/src/symbole/grundzeichen/fahrzeug', false, /\.(png|jpe?g|svg)$/));
const groesseno = importAll(require.context('/src/symbole/groessenordnung', false, /\.(png|jpe?g|svg)$/));
const fue = importAll(require.context('/src/symbole/fuelog/fahrzeug/fue', false, /\.(png|jpe?g|svg)$/));
const log = importAll(require.context('/src/symbole/fuelog/fahrzeug/log', false, /\.(png|jpe?g|svg)$/))
const bewegl = importAll(require.context('/src/symbole/beweglichkeit', false, /\.(png|jpe?g|svg)$/))
const ortsf = importAll(require.context('/src/symbole/ortsfest', false, /\.(png|jpe?g|svg)$/))

export default function ZeichenFahrzeug (
    { data }) {

        const ref = useRef(null)
        let fileName
        if (data.groessenordnung==="keine") {
            fileName = `taktZ_${data.grundzeichen}_${data.activeSymboleInnen.join("+")}.png`
        } else {
            fileName = `taktZ_${data.groessenordnung}_${data.activeSymboleInnen.join("+")}.png`
        }
        const onButtonClick = useCallback(() => {
            if (ref.current === null) {
                return
            }
    
            toPng(ref.current, { cacheBust: true, })
                .then((dataUrl) => {
                    const link = document.createElement('a')
                    link.download = fileName
                    link.href = dataUrl
                    link.click()
                })
                .catch((err) => {
                    console.log(err)
                })
        }, [ref])

    const imgList = data.activeSymboleInnen.map((symbol) => <img src={symbole[`${symbol}.png`]} />)



    return (
        <div className="Container ZeichenFahrzeug" >
            <div className="Fahrzeug-Innen" id="domEl" ref={ref} >
                <div className="Fahrzeug-Grundzeichen Fahrzeug-Container">
                    <img src={hintergrund[`fill-${data.fuellfarbe}.png`]} />
                    <img src={hintergrund[`line-${data.linienfarbe}.png`]} />
                </div>
                <div className="Fahrzeug-Groessenordnung Fahrzeug-Container">
                    <img src={groesseno[`${data.groessenordnung}.png`]} />
                </div>
                <div className="Fahrzeug-Beweglichkeit Fahrzeug-Container">
                    <img src={bewegl[`${data.linienfarbe}-${data.beweglichkeit}.png`]} />
                </div>
                <div className="Fahrzeug-Vstkverm Fahrzeug-Container">
                    <p>{data.vstkverm === "Verstärkt (+)" ? "(+)" : (data.vstkverm === "Vermindert (-)" ? "(-)" : (data.vstkverm === "Vstk/Verm (+/-)" ? "(+/-)" : ""))}</p>
                </div>
                <div className="Fahrzeug-FreitextAussen Fahrzeug-Container">
                    <p>{data.freitextAussenObenRechts}</p>
                </div>
                <div className={`Fahrzeug-Container ${data.fuehrung ? "Fahrzeug-Fuehrung-On" : "Fahrzeug-Fuehrung-Off"} `}>
                    <img src={fue[`${data.linienfarbe}.png`]} />
                </div>
                <div className={`Fahrzeug-Container ${data.ortsfest ? "Fahrzeug-Ortsfest-On" : "Fahrzeug-Ortsfest-Off"} `}>
                    <img src={ortsf[`${data.linienfarbe}.png`]} />
                </div>
                <div className="Fahrzeug-Container Fahrzeug-FreitextInnenOben">
                    <p>{data.freitextInnenObenLinks}</p>
                </div>
                <div className={`Fahrzeug-Container ${data.logistik ? "Fahrzeug-FreitextInnenUnten-Logistik" : "Fahrzeug-FreitextInnenUnten"} `}>
                    <p>{data.freitextInnenUntenRechts}</p>
                </div>
                <div className="Fahrzeug-Symbol Fahrzeug-Container">
                    <p>{data.textInput}</p>
                    {imgList}
                </div>
                <div className={`Fahrzeug-Container ${data.logistik ? "Fahrzeug-Logistik-On" : "Fahrzeug-Logistik-Off"} `}>
                    <img src={log[`${data.linienfarbe}.png`]} />
                </div>
                <div className="Fahrzeug-Rufname Fahrzeug-Container">
                    <p>{data.rufname}</p>
                </div>
                <div className={`Fahrzeug-Container ${data.staerke!=="" ? "Fahrzeug-Von-Staerke" : "Fahrzeug-Von"} `}>
                    <p>{data.bewegungVon}</p>
                </div>
                <div className={`Fahrzeug-Container ${data.staerke!=="" ? "Fahrzeug-Nach-Staerke" : "Fahrzeug-Nach"} `}>
                    <p>{data.bewegungNach}</p>
                </div>
                <div className={`Fahrzeug-Container ${data.staerke!=="" ? "Fahrzeug-Bewegungsart-Staerke" : "Fahrzeug-Bewegungsart"} `}>
                    <img src={symbole[`${data.bewegungsart}.png`]} />
                </div>
                <div className="Fahrzeug-Staerke Fahrzeug-Container">
                    <p>{data.staerke}</p>
                </div>
                <div className="Fahrzeug-Dtg Fahrzeug-Container">
                    <p>{data.dtg}</p>
                </div>
            </div>
            <div className="Fahrzeug-Download Fahrzeug-Container">
                <button onClick={onButtonClick} >Download</button>
            </div>
        </div>
    )
}
