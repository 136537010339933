import React from "react";

export default function Grundzeichen ({grundzeichen, onChangeGrundzeichen}) {
    return(
        <div className="Container Grundzeichen">
            <label htmlFor="grundzeichen">Grundzeichen</label>
            <select onChange={onChangeGrundzeichen} id="grundzeichen">
                <option >Einheit</option>
                <option >Person</option>
                <option >Fahrzeug</option>
                <option >Boot</option>                
                <option >Stelle</option>
                <option >Gefahr</option>
                <option >Maßnahme</option>
                <option >Ereignis</option>
                <option disabled >Sonstiges</option>
            </select>
        </div>
    )
}