import React, { useRef, useCallback } from "react";
import "./ZeichenBoot.css";
import { toPng } from 'html-to-image';

function importAll(r) {
    let symbole = {};
    r.keys().map((item, index) => { symbole[item.replace('./', '')] = r(item); });
    return symbole;
}

const symbole = importAll(require.context('/src/symbole/innen', false, /\.(png|jpe?g|svg)$/));
const hintergrund = importAll(require.context('/src/symbole/grundzeichen/boot', false, /\.(png|jpe?g|svg)$/));
const groesseno = importAll(require.context('/src/symbole/groessenordnung', false, /\.(png|jpe?g|svg)$/));
const fue = importAll(require.context('/src/symbole/fuelog/boot/fue', false, /\.(png|jpe?g|svg)$/));
const log = importAll(require.context('/src/symbole/fuelog/boot/log', false, /\.(png|jpe?g|svg)$/))

export default function ZeichenBoot(
    { data }) {

    const ref = useRef(null)
    let fileName
    if (data.groessenordnung==="keine") {
        fileName = `taktZ_${data.grundzeichen}_${data.activeSymboleInnen.join("+")}.png`
    } else {
        fileName = `taktZ_${data.groessenordnung}_${data.activeSymboleInnen.join("+")}.png`
    }
    const onButtonClick = useCallback(() => {
        if (ref.current === null) {
            return
        }

        toPng(ref.current, { cacheBust: true, })
            .then((dataUrl) => {
                const link = document.createElement('a')
                link.download = fileName
                link.href = dataUrl
                link.click()
            })
            .catch((err) => {
                console.log(err)
            })
    }, [ref])
    
    const imgList = data.activeSymboleInnen.map((symbol) => <img src={symbole[`${symbol}.png`]} />)

    return (
        <div className="Container ZeichenBoot" >
            <div className="Boot-Innen" id="domEl" ref={ref} >
                <div className="Boot-Grundzeichen Boot-Container">
                    <img src={hintergrund[`fill-${data.fuellfarbe}.png`]} />
                    <img src={hintergrund[`line-${data.linienfarbe}.png`]} />
                </div>
                <div className="Boot-Groessenordnung Boot-Container">
                    <img src={groesseno[`${data.groessenordnung}.png`]} />
                </div>
                <div className="Boot-Vstkverm Boot-Container">
                    <p>{data.vstkverm === "Verstärkt (+)" ? "(+)" : (data.vstkverm === "Vermindert (-)" ? "(-)" : (data.vstkverm === "Vstk/Verm (+/-)" ? "(+/-)" : ""))}</p>
                </div>
                <div className="Boot-FreitextAussen Boot-Container">
                    <p>{data.freitextAussenObenRechts}</p>
                </div>
                <div className={`Boot-Container ${data.fuehrung ? "Boot-Fuehrung-On" : "Boot-Fuehrung-Off"} `}>
                    <img src={fue[`${data.linienfarbe}.png`]} />
                </div>
                <div className="Boot-Container Boot-FreitextInnenOben">
                    <p>{data.freitextInnenObenLinks}</p>
                </div>
                <div className="Boot-Container Boot-FreitextInnenUnten">
                    <p>{data.freitextInnenUntenRechts}</p>
                </div>
                <div className="Boot-Symbol Boot-Container">
                    <p>{data.textInput}</p>
                    {imgList}
                </div>
                <div className={`Boot-Container ${data.logistik ? "Boot-Logistik-On" : "Boot-Logistik-Off"} `}>
                    <img src={log[`${data.linienfarbe}.png`]} />
                </div>
                <div className="Boot-Rufname Boot-Container">
                    <p>{data.rufname}</p>
                </div>
                <div className="Boot-Von Boot-Container">
                    <p>{data.bewegungVon}</p>
                </div>
                <div className="Boot-Nach Boot-Container">
                    <p>{data.bewegungNach}</p>
                </div>
                <div className="Boot-Bewegungsart Boot-Container">
                    <img src={symbole[`${data.bewegungsart}.png`]} />
                </div>
                <div className="Boot-Staerke Boot-Container">
                    <p>{data.staerke}</p>
                </div>
                <div className="Boot-Dtg Boot-Container">
                    <p>{data.dtg}</p>
                </div>
            </div>
            <div className="Boot-Download Boot-Container">
                <button onClick={onButtonClick} >Download</button>
            </div>
        </div>
    )
}
