import React from "react";

export default function FuehrungLogistik({fuehrung, logistik, fuehrungsstelle, toggleFuehrungsstelle, toggleFuehrung, toggleLogistik, ortsfest, toggleOrtsfest}) {
    return (
        <div className="Container FuehrungLogistik" >
            <div>
                <input 
                    type="checkbox" 
                    id="fuehrung" 
                    checked={fuehrung} 
                    onChange={toggleFuehrung} 
                />
                <label htmlFor="fuehrung">Führung</label>
            </div>
            <div>
                <input 
                    type="checkbox" 
                    id="logistik" 
                    checked={logistik} 
                    onChange={toggleLogistik} 
                    />
                <label htmlFor="logistik">Logistik</label>
            </div>
            <div>
                <input 
                    type="checkbox" 
                    id="fuehrungsstelle" 
                    checked={fuehrungsstelle} 
                    onChange={toggleFuehrungsstelle} 
                    />
                <label htmlFor="fuehrungsstelle">Führungsstelle</label>
            </div>
            <div>
                <input 
                    type="checkbox" 
                    id="ortsfest" 
                    checked={ortsfest} 
                    onChange={toggleOrtsfest} 
                    />
                <label htmlFor="ortsfest">ortsfest</label>
            </div>
        </div>
    )
}